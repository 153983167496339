import { BaseApiService } from "./base";

const API_ENDPOINT = "/api/v4/company_profiles";

export class CompanyProfilesApiService extends BaseApiService implements CompanyProfilesApi.Service {
  public all: CompanyProfilesApi.AllFn = (companyType) =>
    this.axios.get(API_ENDPOINT, { params: { company_type: companyType } });

  public get: CompanyProfilesApi.GetFn = (companySlug, key) =>
    this.axios.get(`${API_ENDPOINT}/${companySlug}${key !== undefined ? "?key=" + key : ""}`);

  public getFocusProducts: CompanyProfilesApi.GetFocusProductsFn = (companySlug) =>
    this.axios.get(`${API_ENDPOINT}/${companySlug}/focus_products`);

  public getUnfocusedFunds: CompanyProfilesApi.GetUnfocusedFundsFn = (companySlug) =>
    this.axios.get(`${API_ENDPOINT}/${companySlug}/unfocused_funds`);

  public getFunds: CompanyProfilesApi.GetFundsFn = (companySlug, page, query, perPage) =>
    this.axios.get(`${API_ENDPOINT}/${companySlug}/funds`, { params: { page, query, per_page: perPage } });

  public getPortfolios: CompanyProfilesApi.GetPortfoliosFn = (companySlug) =>
    this.axios.get(`${API_ENDPOINT}/${companySlug}/portfolios`);

  public getContents: CompanyProfilesApi.GetContentsFn = (companySlug, page) =>
    this.axios.get(`${API_ENDPOINT}/${companySlug}/contents`, { params: { page } });

  public getUsers: CompanyProfilesApi.GetUsersFn = (companySlug) =>
    this.axios.get(`${API_ENDPOINT}/${companySlug}/users`);

  public getVanguardUsers: CompanyProfilesApi.GetVanguardUsersFn = (companySlug) =>
    this.axios.get(`${API_ENDPOINT}/${companySlug}/users/vanguard`);

  public getParticipation: CompanyProfilesApi.GetParticipationFn = (companyId, params) =>
    this.axios.get(`${API_ENDPOINT}/${companyId}/participations`, { params });
}
