var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: { title: _vm.tooltip, disabled: _vm.$root.handheld },
          expression: "{ title: tooltip, disabled: $root.handheld }",
          modifiers: { hover: true },
        },
      ],
      ref: "toggle",
      staticClass: "ci-dropdown-item",
      on: {
        mouseover: function ($event) {
          return _vm.expand()
        },
        mouseleave: function ($event) {
          return _vm.collapse()
        },
      },
    },
    [
      _c(
        _vm.tag,
        _vm._b(
          { tag: "component", staticClass: "dropdown-item-link" },
          "component",
          _vm.tagAttributes,
          false
        ),
        [
          _c(
            "div",
            {
              ref: "dropdownItem",
              staticClass:
                "dropdown-item-content d-flex px-3 py-1 align-items-center justify-content-between",
              class: {
                "disabled-item": _vm.disabled,
                active: _vm.toggleActive,
              },
              on: {
                click: function ($event) {
                  return _vm.handleClick()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex w-100 align-items-center" },
                [
                  _vm._t(
                    "item-content",
                    function () {
                      return [
                        _c("span", {
                          staticClass: "mr-3",
                          class: _vm.iconClasses,
                        }),
                        _c("div", { staticClass: "flex-grow-1" }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _c("span", {
                              staticClass: "font-weight-semi flex-grow-1",
                              class: _vm.itemNameClass,
                              domProps: { textContent: _vm._s(_vm.name) },
                            }),
                            _c("div", { staticClass: "ml-3" }, [
                              _vm.expandable
                                ? _c("span", {
                                    staticClass:
                                      "mdi mdi-18px mdi-chevron-right",
                                  })
                                : _vm._e(),
                              _vm.toggleActive
                                ? _c("span", {
                                    staticClass: "mdi mdi-18px mdi-check",
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", {
                            staticClass: "description",
                            domProps: { textContent: _vm._s(_vm.description) },
                          }),
                        ]),
                      ]
                    },
                    { expanded: _vm.expanded }
                  ),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm.expandable
        ? _c(
            "div",
            { ref: "expandedContent", staticClass: "expanded-item-content" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.expanded,
                      expression: "expanded",
                    },
                  ],
                  staticClass: "ci-card",
                },
                [
                  _c(
                    "div",
                    { staticClass: "py-3" },
                    [
                      _vm.$root.handheld
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "ci-dropdown-item d-flex px-3",
                                on: {
                                  click: function ($event) {
                                    return _vm.collapse()
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "mdi mdi-18px mdi-chevron-left mr-3",
                                }),
                                _c("span", {
                                  class: _vm.itemNameClass,
                                  domProps: { textContent: _vm._s(_vm.name) },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c("hr", { staticClass: "xs w-90 dark d-lg-none my-1" }),
                      _vm._t("default"),
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }