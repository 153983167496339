import { BaseApiService } from "./base";

const CONTENTS_API_ENDPOINT = "/api/v4/contents";

export class ContentsApiService extends BaseApiService implements ContentsApi.Service {
  public getContentsList: ContentsApi.GetContentsListFn = (type, page, perPage) =>
    this.axios.get(CONTENTS_API_ENDPOINT, { params: { page, per_page: perPage, type } });

  public getExpertArticles: ContentsApi.GetExpertArticlesFn = (page, perPage) =>
    this.axios.get(`${CONTENTS_API_ENDPOINT}/expert_articles`, {
      params: { page, per_page: perPage },
    });

  public getFeed: ContentsApi.GetFeedFn = (feedContentType, page, perPage) =>
    this.axios.get(`${CONTENTS_API_ENDPOINT}/feed_contents`, {
      params: { page, per_page: perPage, feed_content_type: feedContentType },
    });

  public getRelatedContentRoutes: ContentsApi.GetRelatedContentRoutesFn = (slug: string) =>
    this.axios.get(`${CONTENTS_API_ENDPOINT}/${slug}/content_routes`);
}
