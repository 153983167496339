import { useI18n } from "@composables/i18n";
import { formatDistanceToNow } from "date-fns";
import { utilityService } from "@services/utility.service";
import { ROUTENAMES } from "@models/constants/routes";
import { RawLocation } from "vue-router";
import NotificationLocale_DE from "./notification-distance.i18n.de";
import NotificationLocale_EN from "./notification-distance.i18n.en";
import { dateService } from "@services/date.service";
import { NumberFormatterHelpers } from "@directives/base/number-formatter";

const iconForCategory = (category: string) => {
  switch (category) {
    case "product":
      return "mdi-trending-up";
    case "portfolio":
      return "mdi-chart-areaspline";
    case "event":
      return "mdi-calendar-blank";
    case "webcast":
      return "mdi-calendar-blank";
    case "interest":
      return "mdi-text-box";
    case "profile":
      return "mdi-account";
    default:
      return "mdi-bell";
  }
};

const messageForNotification = (data: Notifications.Item) => {
  const { t, tc } = useI18n();
  const deletedName = t(`ui.shared.global.notifications.deleted`);

  // eslint-disable-next-line default-case
  switch (data.type) {
    case "ProductChangeNotification": {
      if (
        [
          "company__name",
          "fees__ongoing_charges__value",
          "fees__sum_ongoing_charges__value",
          "manager",
          "risk_level__sri",
          "sustainable_preference",
        ].includes(data.attribute)
      ) {
        const name = data.subject?.name ?? deletedName;
        return t(`ui.shared.global.notifications.products.${data.attribute}`, { fund: name });
      }
      if (data.attribute === "characteristics__is_softclosing" || data.attribute === "characteristics__is_closed") {
        const closingType = data.attribute === "characteristics__is_softclosing" ? "soft_closing" : "hard_closing";
        const name = data.subject?.name ?? deletedName;

        const options = {
          fund: name,
          status: t(`ui.shared.global.notifications.products.closing_status.${closingType}`),
        };
        return t(`ui.shared.global.notifications.products.closing_status.text`, options);
      }
      break;
    }
    case "ProductPerformanceAlarmNotification": {
      const name = data.subject?.name ?? deletedName;
      const currency = data.subject?.currency || "";

      if (data.change.after.alarm_type === "price") {
        return t("ui.shared.global.notifications.products.performance_alarm.price", {
          fund: name,
          price: NumberFormatterHelpers.parse(
            NumberFormatterHelpers.parsingOptions(data.change.after.target_value, currency),
            null,
            true
          ),
        });
      }
      return t(`ui.shared.global.notifications.products.performance_alarm.performance.${data.change.after.period}`, {
        fund: name,
        performance: NumberFormatterHelpers.parse(
          NumberFormatterHelpers.parsingOptions(data.change.after.target_value, "%"),
          null,
          false
        ),
      });
    }
    case "ProductAttachmentNotification": {
      const name = data.subject?.name ?? deletedName;

      return t("ui.shared.global.notifications.products.attachment.added", {
        fund: name,
        name: data.change.after,
      });
    }
    case "PortfolioFollowNotification": {
      const name = data.subject?.name ?? deletedName;

      return tc(`ui.shared.global.notifications.portfolios.new_follower_html`, data.relations.length, {
        follower: name,
        relation: data.relations[0].name,
        relation_count: data.relations.length - 1,
      });
    }
    case "PortfolioChangedNotification": {
      const name = data.subject?.name ?? deletedName;

      return t(`ui.shared.global.notifications.portfolios.changed.text`, {
        portfolio: name,
      });
    }
    case "PortfolioNotification": {
      const messageKey =
        data.change.before.performance < data.change.after.performance
          ? "ui.shared.global.notifications.portfolios.performance.up_html"
          : "ui.shared.global.notifications.portfolios.performance.down_html";
      const performanceChange = Math.abs(data.change.after.performance - data.change.before.performance);
      const name = data.subject?.name ?? deletedName;

      return t(messageKey, {
        portfolio: name,
        count: NumberFormatterHelpers.parse(NumberFormatterHelpers.parsingOptions(performanceChange, "%"), null, false),
      });
    }
    case "SocialEventNotification": {
      const translationKey =
        data.attribute === "new_event"
          ? "ui.shared.global.notifications.events.new_event_html"
          : "ui.shared.global.notifications.events.new_webinar_html";
      const title = data.subject?.headline ?? deletedName;

      return t(translationKey, { title: title });
    }
    case "NewArticleNotification":
    case "NewPodcastNotification":
    case "NewVideoNotification":
    case "NewWebcastNotification": {
      const type = data.subject?.type ?? "Article";
      const title = data.subject?.title ?? deletedName;

      return t(`ui.shared.global.notifications.interests.new_content.${type}_html`, {
        title: title,
      });
    }
    case "NewProductNotification": {
      const relationsLength = data.relations ? data.relations.length + 1 : 1;
      const name = data.subject?.name ?? deletedName;

      return tc(`ui.shared.global.notifications.interests.new_product_html`, relationsLength, {
        subject_name: name,
        relation_count: relationsLength - 1,
      });
    }
    case "UserProfileViewNotification": {
      const name = data.subject?.name ?? deletedName;

      return t(`ui.shared.global.notifications.profiles.new_visitor_html`, {
        visitor: name,
      });
    }
  }

  throw new Error(`Couldn't determine message for: ${JSON.stringify(data)}`);
};

const ageForNotification = (timestamp: string) => {
  const locale = ["de", "de-DE"].includes(utilityService.currentLocale) ? NotificationLocale_DE : NotificationLocale_EN;

  return formatDistanceToNow(new Date(timestamp), { locale });
};

const linkForNotification = (data: Notifications.Item): RawLocation => {
  if (data.type === "PortfolioChangedNotification" && data.subject) {
    return {
      name: ROUTENAMES.PORTFOLIOS_SHOW,
      params: { id: data.subject.slug.toString() },
      query: { modal: "portfolioHistory" },
    };
  }

  switch (data.subject?.record_type) {
    case "Fund":
      return { name: ROUTENAMES.PRODUCTS_SHOW, params: { id: data.subject.slug.toString() } };
    case "Portfolio":
      return { name: ROUTENAMES.PORTFOLIOS_SHOW, params: { id: data.subject.slug.toString() } };
    case "User":
      return { name: ROUTENAMES.USERS_SHOW, params: { slug: data.subject.slug.toString() } };
    case "SocialEvent":
      return { name: ROUTENAMES.EVENTS_SHOW, params: { id: data.subject.id.toString() } };
    case "Article":
    case "Podcast":
    case "Video":
    case "Webcast":
      return data.subject.url;
    default:
      return "#";
  }
};

const changeForNotification = (notification: Notifications.Item): Notifications.MappedChange => {
  if (notification.change.before === null && notification.change.after === null) return null;
  if (typeof notification.change.before === "boolean" || typeof notification.change.after === "boolean") return null;
  if (notification.type === "ProductPerformanceAlarmNotification") return null;

  if (notification.attribute === "risk_level__sri")
    return {
      before: notification.change.before,
      after: notification.change.after,
      component: "ci-rating-sri",
      componentOptions: { short: true },
      date: null,
    };
  if (notification.attribute === "sustainable_preference")
    return { before: notification.change.before, after: notification.change.after, component: "ci-sfdr", date: null };

  if (typeof notification.change.before === "string" || typeof notification.change.after === "string") {
    return {
      before: (notification.change.before || "-") as string,
      after: (notification.change.after || "-") as string,
      date: null,
      component: null,
    };
  }

  if (typeof notification.change.before === "number" || typeof notification.change.after === "number") {
    return {
      before: NumberFormatterHelpers.parse(
        NumberFormatterHelpers.parsingOptions(notification.change.before as number, "%"),
        null,
        false
      ),
      after: NumberFormatterHelpers.parse(
        NumberFormatterHelpers.parsingOptions(notification.change.after as number, "%"),
        null,
        false
      ),
      date: null,
      component: null,
    };
  }

  if (typeof notification.change.before === "object" || typeof notification.change.after === "object") {
    const before = notification.change.before as Nullable<{ performance: number; time: string }>;
    const after = notification.change.after as Nullable<{ performance: number; time: string }>;

    return {
      before: NumberFormatterHelpers.parse(
        NumberFormatterHelpers.parsingOptions(before?.performance, "%"),
        null,
        false
      ),
      after: NumberFormatterHelpers.parse(NumberFormatterHelpers.parsingOptions(after?.performance, "%"), null, false),
      date: dateService.format("medium", before?.time),
      component: null,
    };
  }

  throw new Error(`Cannot format change of ${JSON.stringify(notification)}`);
};

const mapNotification = (data: Notifications.Item): Notifications.MappedItem => {
  const icon = iconForCategory(data.category);
  const age = ageForNotification(data.created_at);
  const to = linkForNotification(data);
  const change = changeForNotification(data);

  return {
    icon,
    age,
    message: messageForNotification(data) as string,
    to,
    collapsed: true,
    original: data,
    change,
  };
};

export const NotificationMapper = {
  map: mapNotification,
  iconForCategory,
  messageForNotification,
  ageForNotification,
  linkForNotification,
};
