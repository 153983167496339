export enum Period {
  MONTH_1 = "_1_month",
  MONTH_3 = "_3_months",
  MONTH_6 = "_6_months",
  YEAR_1 = "_1_year",
  YEAR_2 = "_2_years",
  YEAR_3 = "_3_years",
  YEAR_5 = "_5_years",
  YEAR_7 = "_7_years",
  YEAR_10 = "_10_years",
  YEAR_15 = "_15_years",
  YEAR_20 = "_20_years",
  YEAR_YTD = "_ytd",
  SINCE_INCEPTION = "_since_inception",
}

export enum SharingNetworks {
  CustomEmail = "custom_email",
  CustomMessaging = "custom_messaging",
  LinkedIn = "linkedin",
  Twitter = "twitter",
  Facebook = "facebook",
}

export enum TrackedButtonPlacement {
  GLOBAL = "global",
  PROMOTION_BANNER = "promotionBanner",
}

/**  */
export enum ViewportSizes {
  XXS = 0,
  XS = 360,
  SM = 480,
  MD = 768,
  LG = 1000,
  XL = 1300,
  XXL = 1600,
}

export enum EventCategory {
  Product = "Product",
  Certificate = "Certificate",
}

export enum Modals {
  CONTACT_FORM_MODAL = "contact-form-modal",
  CONTACT_SUCCESS_MODAL = "contact-success-modal",
  DEPOT_UPLOAD = "depot-upload",
  PORTFOLIOS_CREATE = "portfolios-create-modal",
  PORTFOLIOS_CREATE_WITH_PRODUCTS = "portfolios-create-with-products-modal",
  PORTFOLIOS_EDIT_NAME = "portfolios-edit-name-modal",
  PORTFOLIOS_HISTORY = "portfolios-history-modal",
  PORTFOLIOS_FOLLOWERS = "portfolios-followers-modal",
  RESTRICTABLE = "restrictable-modal",
  PRODUCT_ALARM = "product-alarm-modal",
  USER_CONTACT_MODAL = "user-contact-modal",
  USER_EDIT_RESUME_ITEM = "user-edit-resume-item-modal",
  USER_EDIT_QUALIFICATION_ITEM = "user-edit-qualification-item-modal",
  USER_EDIT_EMPTY_IMPRINT_CONFIRMATION = "user-edit-empty-imprint-confirmation-modal",
  WEBCAST = "webcast-modal",
}

export enum UserSalutations {
  MALE = "user.salutations.male",
  FEMALE = "user.salutations.female",
  DIVERS = "user.salutations.divers",
  NONE = "user.salutations.none",
}

export enum UserAges {
  AGE_18_24 = "user.age.18-24",
  AGE_25_29 = "user.age.25-29",
  AGE_30_39 = "user.age.30-39",
  AGE_40_49 = "user.age.40-49",
  AGE_50_59 = "user.age.50-59",
  AGE_ABOVE_60 = "user.age.above60",
}

export enum UserTitles {
  DR = "user.title.dr",
  PROF = "user.title.prof",
  PROF_DR = "user.title.prof_dr",
}
