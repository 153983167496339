var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.hasSmallTeaser
      ? _c(
          "div",
          { key: "small", staticClass: "ci-card ci-content-small-tile" },
          [
            _c(
              "ci-router-link",
              {
                staticClass:
                  "ci-content-tile__content w-100 d-flex flex-column flex-grow-1 ci-content-tile__link py-2 py-lg-2 px-3 px-lg-3",
                attrs: { to: _vm.linkWithTracking },
              },
              [
                _c("div", { staticClass: "flex-grow-1" }, [
                  _vm.tileData.copyright && _vm.showCopyright
                    ? _c(
                        "div",
                        {
                          staticClass: "ci-content-tile__copyright text-muted",
                        },
                        [
                          _c("span", {
                            staticClass: "text-xs",
                            domProps: {
                              textContent: _vm._s(_vm.tileData.copyright),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ci-content-tile__headline d-flex align-items-center",
                    },
                    [
                      !_vm.isShort && _vm.typeIcon
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ci-content-tile__icon mr-3 flex-shrink-0 flex-center",
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "mdi mdi-play-circle-outline mdi-40px flex-shrink-0 text-primary",
                                class: _vm.typeIcon,
                              }),
                            ]
                          )
                        : _vm._e(),
                      _c("h4", {
                        ref: "headline",
                        staticClass: "headline text-dark",
                        class: _vm.mobileMode ? "h6" : "h5",
                        domProps: { textContent: _vm._s(_vm.tileData.title) },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            directives: [
              {
                name: "ci-track-impression",
                rawName: "v-ci-track-impression",
                value: _vm.impressionObject,
                expression: "impressionObject",
              },
            ],
            key: "full",
            staticClass: "ci-card ci-content-tile",
            class: _vm.variantClass,
          },
          [
            _c(
              "ci-router-link",
              {
                staticClass: "ci-content-tile__header",
                attrs: { to: _vm.linkWithTracking },
              },
              [
                _c(
                  "div",
                  { staticClass: "ci-content-tile__image" },
                  [
                    _c("ci-imgix-lazy", {
                      attrs: {
                        url: _vm.tileData.img.url,
                        sizes: _vm.imageSizes,
                        params: _vm.imageParams,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "ci-router-link",
              {
                staticClass:
                  "ci-content-tile__content w-100 d-flex flex-column flex-grow-1 ci-content-tile__link",
                class: _vm.isShort ? "p-3" : "ci-content-padding",
                attrs: { to: _vm.linkWithTracking },
              },
              [
                _c("div", { staticClass: "flex-grow-1" }, [
                  _vm.tileData.copyright && _vm.showCopyright
                    ? _c(
                        "div",
                        {
                          staticClass: "ci-content-tile__copyright text-muted",
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.tileData.copyright),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ci-content-tile__headline d-flex align-items-center mt-2 mb-3",
                    },
                    [
                      !_vm.isShort && _vm.typeIcon
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ci-content-tile__icon mr-3 flex-shrink-0 flex-center",
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "mdi mdi-play-circle-outline mdi-40px flex-shrink-0 text-primary",
                                class: _vm.typeIcon,
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.isShort
                        ? [
                            _c("h4", {
                              directives: [
                                {
                                  name: "line-clamp",
                                  rawName: "v-line-clamp",
                                  value: _vm.maxConversationHeadlineLines,
                                  expression: "maxConversationHeadlineLines",
                                },
                              ],
                              ref: "headline",
                              staticClass: "h6 headline word-break-word",
                              class: {
                                truncated: !_vm.isShort && _vm.typeIcon,
                              },
                              domProps: {
                                textContent: _vm._s(_vm.tileData.title),
                              },
                            }),
                          ]
                        : [
                            _c("h4", {
                              directives: [
                                {
                                  name: "line-clamp",
                                  rawName: "v-line-clamp",
                                  value: _vm.maxHeadlineLines,
                                  expression: "maxHeadlineLines",
                                },
                              ],
                              ref: "headline",
                              staticClass: "h5 headline word-break-word",
                              class: {
                                truncated: !_vm.isShort && _vm.typeIcon,
                              },
                              domProps: {
                                textContent: _vm._s(_vm.tileData.title),
                              },
                            }),
                          ],
                    ],
                    2
                  ),
                  !_vm.isShort && _vm.teaserClamp > 0
                    ? _c("div", { staticClass: "ci-content-tile__teaser" }, [
                        _c("p", {
                          directives: [
                            {
                              name: "line-clamp",
                              rawName: "v-line-clamp",
                              value: _vm.teaserClamp,
                              expression: "teaserClamp",
                            },
                          ],
                          staticClass: "word-break-word",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$sanitize(_vm.tileData.teaser)
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
                _vm.isFlipperTagsArticleTilesEnabled &&
                _vm.tileData.interest_tags.length
                  ? _c(
                      "div",
                      { staticClass: "ci-content-tile__interest_tags d-flex" },
                      [
                        _c("ci-interest-tags", {
                          class: _vm.isShort ? "mt-2" : "mb-2",
                          attrs: {
                            "interest-tags": _vm.tileData.interest_tags,
                            clamp: 1,
                            tagTrackingIdentifierOverride:
                              _vm.interestTagsTrackingIdentifierOverride,
                            "tag-tracking-target-name": "TagArticleTile",
                            context: "articles",
                            variant: "link",
                            "show-expand-cta": false,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-shrink-0 align-items-center justify-content-start",
                    class: _vm.isShort ? "d-none" : "d-flex",
                  },
                  [
                    _c("div", { staticClass: "ci-content-tile__meta mr-3" }, [
                      _c("div", { staticClass: "mdi mdi-calendar-blank mr-1" }),
                      _c("time", {
                        directives: [
                          {
                            name: "ci-format-date",
                            rawName: "v-ci-format-date",
                            value: {
                              value: _vm.tileData.publication_date,
                              opts: {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                              },
                            },
                            expression:
                              "{ value: tileData.publication_date, opts: { year: '2-digit', month: '2-digit', day: '2-digit' } }",
                          },
                        ],
                      }),
                    ]),
                    _vm.tileData.time_consumption
                      ? _c(
                          "div",
                          { staticClass: "ci-content-tile__reading_time mr-3" },
                          [
                            _c("div", {
                              staticClass: "mdi mdi-timer-outline mr-1",
                            }),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$tc(
                                    "ui.shared.global.minutes.abbr",
                                    _vm.tileData.time_consumption
                                  )
                                ),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    !_vm.hideActions
                      ? [
                          !_vm.isShort
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ci-content-tile__actions d-flex ml-auto",
                                },
                                [
                                  _c("ci-bookmark", {
                                    attrs: {
                                      item: _vm.tileData,
                                      type: "contents",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }