import { useI18n } from "@composables/i18n";
import { computed, getCurrentInstance, Ref } from "vue";
import { CurrentUser } from "@utils/user";
import { ROUTENAMES } from "@models/constants/routes";
import { apiService } from "@services/api.service";
import { CreateToast } from "@utils/toasts";
import { portfoliosService } from "@services/portfolios.service";
import { ciTrackingService } from "@services/ci-tracking.service";
import { ModalStorePortfoliosHistory } from "@components/portfolios/modals/history-modal.store";
import { ModalStorePortfoliosFollowers } from "@components/portfolios/modals/followers-modal.store";
import { ModalStorePortfoliosEditName } from "@components/portfolios/modals/edit-name-modal.store";
import { HandleRouterError } from "@router/error-handler";
import { useRouter } from "vue-router/composables";
import { SharingNetworks } from "@models/enums";

export const usePortfolioActions = (portfolio: Portfolios.TileData | Portfolios.Data) => {
  const { t } = useI18n(getCurrentInstance());
  const router = useRouter();

  const isOwner = computed(() => (portfolio?.owner.type === "User" ? CurrentUser.id === portfolio.owner.id : false));

  const editAttrs: Ref<Indexable> = computed(() => {
    const productCount = portfolio.portfolio_products.length;

    const label = productCount === 0 ? t("ui.shared.portfolios.actions.add_product") : t("ui.shared.global.edit");
    const icon = "mdi-pencil";
    const disabled = false;

    return {
      button: {
        variant: "filled-primary",
        icon,
        tooltip: label,
        label,
        disabled,
        to: { name: ROUTENAMES.PORTFOLIOS_EDIT, params: { id: portfolio.slug } },
      },
      dropdown: {
        iconClasses: `mdi ${icon}`,
        name: label,
        disabled,
      },
      visible: "can_edit" in portfolio ? portfolio.can_edit : false,
    };
  });

  const viewOnlyMode: Ref<boolean> = computed(() => !isOwner.value && !portfolio.followed);

  const followHint: string = t("ui.shared.portfolios.interaction_requires_following") as string;

  const historyAttrs: Ref<Indexable> = computed(() => {
    const label = t("ui.shared.portfolios.actions.show_history");
    const icon = "mdi-history";
    const disabled = viewOnlyMode.value;

    return {
      button: {
        icon,
        tooltip: viewOnlyMode.value ? followHint : label,
        notificationBubble:
          Date.parse(portfolio.updated_at) > (Date.parse(portfolio.changes_seen_at as string) || 0) && !isOwner.value,
        disabled,
      },
      dropdown: {
        iconClasses: `mdi ${icon}`,
        name: label,
        disabled,
      },
      visible: true,
    };
  });

  const visibilityAttrs: Ref<Indexable> = computed(() => {
    const label = t("ui.shared.portfolios.actions.set_visibility.title");
    const icon = portfolio.public_portfolio ? "mdi-earth" : "mdi-lock";
    const disabled = !isOwner.value;

    return {
      button: {
        icon,
        tooltip: label,
        disabled,
      },
      dropdown: {
        iconClasses: `mdi ${icon}`,
        name: t("ui.shared.portfolios.actions.set_visibility.title"),
        disabled,
      },
      dropdownItems: {
        public: {
          name: t("ui.shared.global.visibility.public"),
          description: t("ui.shared.global.visibility.public_description"),
          iconClasses: "mdi mdi-18px mdi-earth",
          toggleActive: portfolio.public_portfolio,
        },
        private: {
          name: t("ui.shared.global.visibility.private"),
          description: t("ui.shared.global.visibility.private_description"),
          iconClasses: "mdi mdi-18px mdi-lock",
          toggleActive: !portfolio.public_portfolio,
        },
      },
      visible: isOwner.value,
    };
  });

  const duplicateAttrs: Ref<Indexable> = computed(() => {
    const label = t("ui.shared.global.duplicate");
    const icon = "mdi-content-copy";
    const disabled = viewOnlyMode.value;

    return {
      button: {
        icon,
        tooltip: viewOnlyMode.value ? followHint : label,
        disabled,
      },
      dropdown: {
        iconClasses: `mdi ${icon}`,
        name: label,
        disabled,
      },
      visible: true,
    };
  });

  const deleteAttrs: Ref<Indexable> = computed(() => {
    const label = t("ui.shared.global.delete");
    const icon = "mdi-delete";
    const disabled = "can_destroy" in portfolio ? !portfolio.can_destroy : true;

    return {
      button: {
        icon,
        tooltip: label,
        disabled,
      },
      dropdown: {
        iconClasses: `mdi ${icon}`,
        name: label,
        disabled,
      },
      visible: "can_destroy" in portfolio ? portfolio.can_destroy : false,
    };
  });

  const moreAttrs: Ref<Indexable> = computed(() => {
    const label = t("ui.shared.global.options");
    const icon = "mdi-dots-vertical";
    const disabled = false;

    return {
      button: {
        icon,
        tooltip: label,
        disabled,
      },
      dropdown: {
        iconClasses: `mdi ${icon}`,
        name: label,
        disabled,
      },
      visible: true,
    };
  });

  const shareAttrs: Ref<Indexable> = computed(() => {
    const sharingOptions = {
      trackingTarget: {
        name: "Portfolio",
        identifier: portfolio?.id.toString(),
      },
      sharingUrl: location.origin + `/portfolios/${portfolio?.slug}`,
      sharingNetworks: [SharingNetworks.CustomEmail, SharingNetworks.CustomMessaging],
    };
    const label =
      !isOwner.value && !portfolio.public_portfolio
        ? (t("ui.shared.portfolios.actions.share_private") as string)
        : (t("ui.shared.global.share") as string);
    const disabled = viewOnlyMode.value || (!isOwner.value && !portfolio.public_portfolio);
    const base = {
      tooltip: viewOnlyMode.value ? followHint : label,
      disabled,
      title: portfolio.name,
      type: "portfolio",
      networks: [SharingNetworks.CustomEmail, SharingNetworks.CustomMessaging],
      networkTooltips: {
        custom_email: portfolio.public_portfolio ? null : t("ui.shared.portfolios.actions.share_email_private"),
      },
      trackingTarget: sharingOptions.trackingTarget,
      url: sharingOptions.sharingUrl,
      disabledNetworks: portfolio.public_portfolio ? [] : [SharingNetworks.CustomEmail],
    };

    return {
      button: base,
      disabled,
      dropdown: {
        ...base,
        dropdown: false,
        dropdownPosition: "right",
      },
      visible: true,
    };
  });

  const followAttrs: Ref<Indexable> = computed(() => ({
    visible: !isOwner.value,
  }));

  const allowedToSetVisibility: Ref<boolean> = computed(
    () => isOwner.value && (!portfolio.public_portfolio || portfolio?.followers_count === 0)
  );

  const setVisibility = async (publicPortfolio: boolean) => {
    try {
      const response = await apiService.portfolios.update(portfolio.id, { public_portfolio: publicPortfolio });
      portfolio.public_portfolio = response.data.public_portfolio;
      CreateToast.success(t("ui.shared.portfolios.actions.set_visibility.success") as string);
      return response.data;
    } catch (err: any) {
      CreateToast.error(err.message);
    }

    return null;
  };

  const duplicate = async () => {
    try {
      await portfoliosService.duplicatePortfolio(portfolio);
    } catch (err: any) {
      CreateToast.error(err.message);
    }
  };

  const rename = () => {
    ModalStorePortfoliosEditName.show({ portfolio: portfolio });
  };

  const remove = async (options: { redirectToIndex?: boolean } = {}) => {
    try {
      await apiService.portfolios.remove(portfolio.id);

      if (options.redirectToIndex) {
        router.replace({ name: ROUTENAMES.PORTFOLIOS_INDEX }).catch((err) => HandleRouterError(err));
      }
    } catch (err: any) {
      CreateToast.error(err.message);
    }
  };

  const showHistory = () => {
    ciTrackingService.trackClick("PortfolioChangelog", portfolio.id.toString());
    ModalStorePortfoliosHistory.show({ portfolio: portfolio });
  };

  const openFollowersModal = () => {
    ModalStorePortfoliosFollowers.show({ portfolio: portfolio });
  };

  return {
    viewOnlyMode,
    isOwner,
    editAttrs,
    moreAttrs,
    historyAttrs,
    visibilityAttrs,
    duplicateAttrs,
    followAttrs,
    shareAttrs,
    deleteAttrs,
    allowedToSetVisibility,
    showHistory,
    duplicate,
    setVisibility,
    rename,
    remove,
    openFollowersModal,
  };
};
