import { Emitter } from "@utils/events";
import { throttle } from "throttle-debounce";
import { DirectiveOptions } from "vue";

const collapsedClass = "header-collapsed";

class ScrollHandler {
  public options: { showOnScrollUp?: boolean } = {};
  public element;
  public scrollPos = 0;
  public direction = "";

  public debouncedScrollPos = 0;
  public sourceHeight = 0;
  public adaptedHeight = 0;

  public debounced;

  constructor(element: HTMLElement, options: { showOnScrollUp?: boolean }) {
    this.element = element;
    this.options = options;

    this.sourceHeight = element.clientHeight;
    this.adaptedHeight = element.clientHeight;

    this.debounced = throttle(50, this.updateToggleState);
  }

  public updateToggleState = (): void => {
    const distanceToTop = document.body.getBoundingClientRect().top;
    if (this.options.showOnScrollUp) this.updateToggleStateOnScrollDirection(distanceToTop);
    else this.updateToggleStateOnScrollPosition(distanceToTop);
  };

  public handle = () => {
    this.direction = document.body.getBoundingClientRect().top >= this.scrollPos ? "up" : "down";
    this.scrollPos = document.body.getBoundingClientRect().top;

    this.debounced();
  };

  private addClass = () => {
    this.element.classList.add(collapsedClass);
    Emitter.emit("ci::collapsible-header::updated", { id: this.element.id, collapsed: true });
  };
  private removeClass = () => {
    this.element.classList.remove(collapsedClass);
    Emitter.emit("ci::collapsible-header::updated", { id: this.element.id, collapsed: false });
  };

  private updateToggleStateOnScrollPosition(distanceToTop: number) {
    if (-distanceToTop > this.element.clientHeight && !this.element.classList.contains(collapsedClass)) {
      this.addClass();
    }
    if (-distanceToTop < this.element.clientHeight && this.element.classList.contains(collapsedClass)) {
      this.removeClass();
    }
  }
  private updateToggleStateOnScrollDirection(distanceToTop: number) {
    if (this.direction === "down" || !this.direction) {
      if (-distanceToTop >= this.element.clientHeight && !this.element.classList.contains(collapsedClass)) {
        this.addClass();
        this.adaptedHeight = this.element.clientHeight;
      }
    } else {
      if (
        this.element.classList.contains(collapsedClass) &&
        (distanceToTop > this.debouncedScrollPos + (this.adaptedHeight - this.sourceHeight) ||
          distanceToTop > this.element.clientHeight)
      ) {
        this.removeClass();
      }
    }

    this.debouncedScrollPos = distanceToTop;
  }
}

let handler: ScrollHandler;

const directive: DirectiveOptions = {
  bind: (element, binding) => {
    handler = new ScrollHandler(element, binding.value || {});
    setTimeout(() => {
      window.addEventListener("scroll", handler.handle);
    });
    setTimeout(() => handler.updateToggleState(), 50);
  },
  unbind: () => {
    window.removeEventListener("scroll", handler.handle);
  },
};

export default directive;
