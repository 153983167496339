import { apiService } from "./api.service";
import { utilityService } from "./utility.service";
import { routingService } from "@services/routing.service";
import { settingsService } from "@services/settings.service";
import { Constants } from "@app/constants";

class Service implements CiTracking.Service {
  public impressions: CiTracking.Impression[] = [];

  public trackImpression: CiTracking.TrackImpressionFn = async (impression) => {
    try {
      await this.rejectDuplicateImpression(impression);
      this.impressions.push(impression);
      return apiService.axios.post("/api/v4/tracking", this.createPayload(impression));
    } catch (err) {
      // eslint-disable-next-line
      return Promise.reject("duplicate");
    }
  };

  public trackView: CiTracking.TrackViewFn = async (
    targetName: string,
    targetIdentifier: string = "unused",
    sourceName: Nullable<string> = null,
    eventId: Nullable<string> = null,
    eventValue: Nullable<number> = null
  ) => {
    const trackingObject: CiTracking.TrackingData = {
      event: this.buildEventForViewTracking(eventId, eventValue),
      target: {
        name: targetName,
        identifier: targetIdentifier,
      },
      source: this.buildSourceForViewTracking(sourceName),
    };

    return await this.track(trackingObject);
  };

  public trackClick: CiTracking.TrackClickFn = async (
    targetName: string,
    targetIdentifier: string = "unused",
    sourceName: Nullable<string> = null,
    targetUrl: Nullable<string> = null,
    eventValue: Nullable<number> = null
  ) => {
    const trackingObject: CiTracking.TrackingData = {
      event: {
        action: "click",
        value: eventValue,
      },
      target: {
        name: targetName,
        identifier: targetIdentifier,
      },
      source: {
        name: sourceName,
        identifier: location.href,
      },
    };
    if (targetUrl !== null) {
      trackingObject.target.url = targetUrl;
    }
    return await this.track(trackingObject);
  };

  public track: CiTracking.TrackFn = async (data) =>
    apiService.axios.post("/api/v4/tracking", this.createPayload(data));

  public resetImpressions: CiTracking.ResetImpressionsFn = () => (this.impressions.length = 0);

  public validateImpression: CiTracking.ValidateImpressionFn = (impression) => {
    const keys = ["event", "source", "target"].every((key) => key in impression);

    if (keys) {
      const event = ["action", "value"].every((key) => key in impression.event);
      const target = ["name", "identifier"].every((key) => key in impression.target);
      const source = ["name"].every((key) => key in impression.source);
      return event && target && source;
    }

    return false;
  };

  public tabChangeSourceIdentifier: CiTracking.TabChangeSourceIdentifierFn = (identifier = location.href) =>
    settingsService.options.app_request ? Constants.ciTracking.appReferrer : identifier;

  private buildEventForViewTracking(
    eventId: Nullable<string> = null,
    value: Nullable<number> = null
  ): CiTracking.TrackingEvent {
    let event: CiTracking.TrackingEvent = {
      action: "view",
      value,
    };
    if (eventId) {
      event = {
        ...event,
        id: eventId,
      };
    }
    return event;
  }

  private buildSourceForViewTracking(sourceName: Nullable<string> = null): CiTracking.TrackingSource {
    const sourceIdentifier = settingsService.options.app_request
      ? Constants.ciTracking.appReferrer
      : routingService.referrer;
    if (sourceName) {
      return {
        name: sourceName,
        identifier: sourceIdentifier,
      };
    }
    // Check for newsletter UTM parameters
    const parsedTargetUrl = new URL(window.location.href);
    const utmMedium = parsedTargetUrl.searchParams.get("utm_medium");
    const utmSource = parsedTargetUrl.searchParams.get("utm_source");
    const utmCampaign = parsedTargetUrl.searchParams.get("utm_campaign");

    if (utmMedium === "email" && utmSource && utmCampaign) {
      return {
        name: utmSource,
        identifier: utmCampaign || sourceIdentifier,
      };
    }

    return {
      name: null,
      identifier: sourceIdentifier,
    };
  }

  private createPayload(data: CiTracking.Impression): CiTracking.TrackingData {
    let payload = data.event.id
      ? data
      : {
          ...data,
          event: {
            ...data.event,
            id: utilityService.guid(),
          },
        };
    payload = payload.target.url
      ? payload
      : {
          ...payload,
          target: {
            ...payload.target,
            url: settingsService.options.app_request ? "unused" : window.location.href,
          },
        };

    if (!payload.source.identifier && settingsService.options.app_request) {
      payload = {
        ...payload,
        source: {
          ...payload.source,
          identifier: Constants.ciTracking.appReferrer,
        },
      };
    }
    return payload as CiTracking.TrackingDataPayload;
  }

  private rejectDuplicateImpression(impression: CiTracking.Impression): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        this.impressions.findIndex(
          (i) => i.target.identifier === impression.target.identifier && i.target.name === impression.target.name
        ) >= 0
      ) {
        reject(new Error("duplicate"));
      } else resolve();
    });
  }
}

export const ciTrackingService = new Service();
